import React, { useState } from 'react';
import { SelfDescribingJson } from '@snowplow/browser-tracker';
import { AspectRatio, HStack, VStack } from '@neui/layout';
import {
  Typography,
  interaction___checkmark,
  Headline,
} from '@neui/styleguide-commerzbank';

import { CdsVideoBasicFeedback } from '@components/VideoFeedback/CdsVideoBasicFeedback';
import { CdsVimeoPlayerComponent } from '@components/VideoPlayer/CdsVimeoPlayer';
import { Layer } from '@components/neui-components/molecules/Layer';
import { CdsVideoFeedback } from '@components/VideoFeedback/CdsVideoFeedback';
import { $t } from '@utils/i18n';
import { IconLink } from '@components/neui-components/atoms/IconLink';
import { getVimeoId } from '@utils/helpers/getVimeoId';
import { SnackbarToast } from '@components/neui-components/molecules/SnackbarToast';
import { baseTheme, oceanTheme } from 'styling/stitches.config';
import { Image } from '@components/Image';

import { ParserComponentBasicProps } from '../util';
import { isDomNode } from '../util';

export default function CdsImgRenderer({
  domNode,
  context,
}: ParserComponentBasicProps & {
  context: SelfDescribingJson<Record<string, unknown>>;
}) {
  const [isFeedbackOpen, setFeedbackOpen] = useState(false);
  const [hasReviewed, setHasReviewed] = useState(false);
  const [displaySnackbar, setDisplaySnackbar] = useState(hasReviewed ?? false);

  if (!isDomNode(domNode)) {
    return <></>;
  }

  const parent = domNode.parent;
  // img has link
  if (parent && parent.name === 'a') {
    // img is actually a video thumbnail
    if (parent?.attribs?.href.includes('vimeo')) {
      const videoId = getVimeoId(parent?.attribs.href);
      return (
        <>
          <VStack spacing={24}>
            <CdsVimeoPlayerComponent
              videoId={videoId}
              imageSrc={domNode?.attribs?.src ?? ''}
              imageAlt={domNode?.attribs?.alt ?? ''}
              context={context}
            />
            <CdsVideoBasicFeedback
              context={context}
              setFeedbackOpen={setFeedbackOpen}
              setHasReviewed={setHasReviewed}
              hasReviewed={hasReviewed}
              setDisplaySnackbar={setDisplaySnackbar}
            />
          </VStack>

          <Layer
            closeLabel={$t('BACK')}
            open={isFeedbackOpen}
            onOpenChange={() => {
              setFeedbackOpen(false);
            }}
          >
            <CdsVideoFeedback
              context={context}
              setFeedbackOpen={setFeedbackOpen}
              setHasReviewed={setHasReviewed}
              hasReviewed={hasReviewed}
              setDisplaySnackbar={setDisplaySnackbar}
            />
          </Layer>

          <SnackbarToast
            position={'right'}
            className={`${baseTheme} ${oceanTheme}`}
            onOpenChange={() => setDisplaySnackbar(!displaySnackbar)}
            open={displaySnackbar}
          >
            <HStack alignItems={'center'} spacing={16}>
              <AspectRatio aspectRatio="1" size={96}>
                <Image
                  src="/icons/snackbar-happy.svg"
                  alt="Happy face illustration"
                />
              </AspectRatio>
              <HStack spacing={64} alignItems="center">
                <VStack>
                  <Headline type="h5">Vielen Dank für das Feedback!</Headline>
                  <Typography size={7}>
                    Jede Rückmeldung hilft, uns zu verbessern.
                  </Typography>
                </VStack>
                <IconLink
                  icon={interaction___checkmark}
                  onClick={() => setDisplaySnackbar(false)}
                  animationDirection="top"
                />
              </HStack>
            </HStack>
          </SnackbarToast>
        </>
      );
    }
  }
  return <></>;
}
