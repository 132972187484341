import { useEffect, useRef, useState } from 'react';
import VimeoPlayer, { Options as VimeoPlayerOptions } from '@vimeo/player';
import { css, styled } from '@neui/core';
import { AspectRatio, Box, HStack } from '@neui/layout';
import { SelfDescribingJson } from '@snowplow/browser-tracker';

import { useTracker } from '@utils/snowplowTracking';
import { Picture } from '@components/Picture/Picture';
import { Image } from '@components/Image';

export const CdsVimeoPlayerComponent = ({
  videoId,
  imageSrc,
  imageAlt,
  context,
}: {
  videoId: number;
  imageSrc: string;
  imageAlt: string;
  context: SelfDescribingJson;
}) => {
  const { trackLinkClick } = useTracker(CdsVimeoPlayerComponent.name);
  const [shouldStart, setShouldStart] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoContainer = useRef(null);

  useEffect(() => {
    const defaultOptions: VimeoPlayerOptions = {
      loop: false,
      autoplay: true,
      dnt: true,
      color: 'FFD700',
      quality: '720p',
      responsive: true,
      pip: false,
      title: true,
      playsinline: true,
      byline: true,
      transparent: false,
      texttrack: 'None',
      keyboard: true,
    };

    if (videoContainer?.current && shouldStart) {
      //put video player in  ref container
      const video = new VimeoPlayer(videoContainer.current, {
        ...defaultOptions,
        id: videoId,
      });
      video.on('play', (event) => {
        trackLinkClick?.('video_played', `${event.percent}`, [], '', [context]);
      });
      video.on('pause', (event) => {
        trackLinkClick?.('video_paused', `${event.percent}`, [], '', [context]);
      });
      video.on('ended', (event) => {
        trackLinkClick?.('video_ended', `${event.percent}`, [], '', [context]);
      });
      video.on('volumechange', async (event) => {
        const muted = await video.getMuted();
        trackLinkClick?.(
          `volumechange, muted: ${muted}`,
          `${event.volume}`,
          [],
          '',
          [context],
        );
      });
      video.on('fullscreenchange', (event) => {
        trackLinkClick?.('fullscreenchange', `${event.fullscreen}`, [], '', [
          context,
        ]);
      });
      video.on('enterpictureinpicture', (event) => {
        trackLinkClick?.('enterpictureinpicture', `${event}`, [], '', [
          context,
        ]);
      });
      video.on('playbackratechange', (event) => {
        trackLinkClick?.(
          'playbackratechange',
          `${event.playbackRate}`,
          [],
          '',
          [context],
        );
      });
      video.on('error', (event) => {
        trackLinkClick?.('error', `${event.message}`, [], '', [context]);
      });

      setIsPlaying(true);
    }
  }, [shouldStart, videoId]);

  return (
    <>
      <Container shouldDisplay={!isPlaying} aspectRatio={'16 / 9'}>
        <HStack>
          <PosterImage
            sources={{
              '(min-width: 1024px)': {
                aspectRatio: 16 / 9,
                widths: [960, 1152],
                sizes: {
                  '(min-width: 1195px)': '1200px',
                  default: '960px',
                },
              },
              '(min-width: 360px)': {
                widths: [672],
                aspectRatio: 16 / 9,
                sizes: {
                  default: '672px',
                },
              },
              default: {
                src: imageSrc,
                aspectRatio: 16 / 9,
                widths: [400],
                sizes: {
                  default: '400px',
                },
              },
            }}
            alt={imageAlt}
          />
          <PosterImageOverlay
            onClick={() => {
              setShouldStart(true);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === 'Space') {
                setShouldStart(true);
              }
            }}
            tabIndex={0}
          >
            {/* icon_play_button.svg */}
            <Image
              src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3MiA3MiI+PHBhdGggZD0iTTM2IDcyQzE2LjEgNzIgMCA1NS45IDAgMzZTMTYuMSAwIDM2IDBzMzYgMTYuMSAzNiAzNi0xNi4xIDM2LTM2IDM2em0tOS42LTQ4LjJ2MjQuM0w1MC43IDM2IDI2LjQgMjMuOHoiIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBmaWxsPSIjZmZmIi8+PC9zdmc+"
              alt="Video abspielen"
            />
          </PosterImageOverlay>
        </HStack>
      </Container>
      <VideoContainer
        aspectRatio={'16 / 9'}
        shouldDisplay={isPlaying}
        ref={videoContainer}
      ></VideoContainer>
    </>
  );
};

const shouldDisplayVariant = {
  shouldDisplay: {
    true: {
      display: 'block',
    },
    false: {
      display: 'none',
    },
  },
};

const Container = styled(AspectRatio, {
  position: 'relative',
  variants: {
    ...shouldDisplayVariant,
  },
});

const VideoContainer = styled(AspectRatio, {
  position: 'relative',
  variants: {
    ...shouldDisplayVariant,
  },
});

const PosterImage = styled(Picture, {
  '& img': {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
});

export const SharedOverlayStyles = css({});

export const SharedPlayButtonStyles = css({});

// Styling for poster / preview image overlay (basically a big button)
const PosterImageOverlay = styled(Box, {
  boxSizing: 'border-box',
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  width: '100%',
  height: '100%',
  minHeight: 100,
  transition: 'background-color 0.4s ease',
  backgroundColor: 'rgba(0, 0, 0, 0.1)',
  '& img': {
    width: 80,
    height: 80,
    cursor: 'pointer',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    opacity: 1,
  },
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
});
